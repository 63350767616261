import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

//Material UI
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
  useMediaQuery,
  Snackbar,
  Alert,
  IconButton,
  Modal,
  Breadcrumbs,
  Link,
} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import CloseIcon from "@mui/icons-material/Close";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel";

//Redux
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../Redux/Actions/cartAction";

//Components
import ProductContainer from "../../Components/Products/ProductContainer";

//Fetch
import axios from "axios";
import baseURL from "../../Assets/common/baseURL";

//Image
import EmptyLogo from "../../Assets/images/Emptylogo.png";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";

function DetailsPage(props) {
  const navigate = useNavigate();

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const item = useLocation().state.product;

  const product = item;

  const [open, setOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [isPack, setPack] = useState(false);
  const [activePrice, setActivePrice] = useState(item.price);

  const [similarProducts, setSimilarProducts] = useState([]);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [image404, setImage404] = useState({
    image1: true,
    image2: true,
  });

  const [selectedDescription, setSelectedDescription] = useState("description");

  //Fetch Similar Products
  useEffect(() => {
    axios.get(`${baseURL}similar/${item.salt}`).then((res) => {
      setSimilarProducts(res.data.data.products.slice(0, 10));
    });
  }, []);

  //Unit or Pack
  const changeType = (event, newType) => {
    if (newType !== null) {
      setPack(newType);
    }
  };

  useEffect(() => {
    isPack ? setActivePrice(item.packprice) : setActivePrice(item.price);
  }, [isPack]);

  //Images
  const imageURL1 = item.image;
  const imageURL2 = item.image_back;
  var images = [imageURL1, imageURL2];

  const imageIndicatorArray = [
    <img
      src={imageURL1}
      height={100}
      width={100}
      style={{ borderRadius: 25 }}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = EmptyLogo;
      }}
    />,
    <img
      src={imageURL2}
      height={100}
      width={100}
      style={{ borderRadius: 25 }}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = EmptyLogo;
      }}
    />,
  ];

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const style = {
    width: 400,
    height: 300,
    backgroundSize: "cover",
  };
  const config = {
    viewedImageSize: 0.8,
    backgroundOpacity: 0.6,
  };

  //Add to Cart
  const payloadData = {
    id: item.id,
    name: item.name,
    qty: quantity,
    image: item.image,
    unit_price: item.price_unit,
    qty_in_pack: item.pieces,
    prescription_required: item.prescription_required,
    price: isPack ? item.price_pack : item.price_unit,
    isPack,
  };

  return (
    <Container maxWidth="md" sx={{ marginTop: 5 }}>
      <Box sx={{ mt: 5, mb: 5 }}>
        <Breadcrumbs>
          <Link underline="hover" color="inherit" href="/">
            Home
          </Link>
          <Link underline="hover" color="inherit">
            Product
          </Link>
        </Breadcrumbs>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            {image404.image1 === true || image404.image2 === true ? (
              <div style={{ position: "relative" }}>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 140,
                    right: 5,
                    zIndex: 100,
                  }}
                >
                  <IconButton onClick={() => openImageViewer(0)}>
                    <ZoomInIcon fontSize="large" color={"primary"} />
                  </IconButton>
                </Box>
                <Carousel
                  animation={"slide"}
                  navButtonsAlwaysInvisible
                  autoPlay={false}
                  swipe={isMatch ? true : false}
                  cycleNavigation={isMatch ? true : false}
                  sx={{ borderRadius: 5 }}
                  indicators={true}
                  IndicatorIcon={imageIndicatorArray}
                  indicatorIconButtonProps={{
                    style: {
                      margin: "10px",
                      boderRadius: "15px",
                    },
                  }}
                  activeIndicatorIconButtonProps={{
                    style: {},
                  }}
                >
                  {images.map((item, index) => (
                    <img
                      src={item}
                      height={"100%"}
                      width={"100%"}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        if (index === 0) {
                          setImage404({
                            ...image404,
                            image1: {
                              image1: false,
                            },
                          });
                        }
                        if (index === 1) {
                          setImage404({
                            ...image404,
                            image2: {
                              image2: false,
                            },
                          });
                        }
                      }}
                      style={{ borderRadius: 25 }}
                      alt={item.title}
                    />
                  ))}
                </Carousel>
              </div>
            ) : (
              <div>
                <img
                  src={EmptyLogo}
                  height={"100%"}
                  width={"100%"}
                  style={{ borderRadius: 25 }}
                />
              </div>
            )}

            <Modal open={isViewerOpen} onClose={closeImageViewer}>
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  justifyContent: "center",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    height: isMatch ? 300 : 600,
                    width: isMatch ? 300 : 600,
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                      display: "flex",
                      justifyContent: "flex-end",
                      zIndex: 100,
                    }}
                  >
                    <IconButton onClick={closeImageViewer}>
                      <CloseIcon color="primary" />
                    </IconButton>
                  </Box>
                  <ResponsiveCarousel
                    emulateTouch
                    showArrows
                    infiniteLoop
                    showThumbs={false}
                    stopOnHover={false}
                    renderArrowPrev={(prev) => {
                      return (
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: 10,
                            zIndex: 101,
                          }}
                        >
                          <IconButton onClick={() => prev()}>
                            <ArrowBackIos color={"primary"} />
                          </IconButton>
                        </div>
                      );
                    }}
                    renderArrowNext={(next) => {
                      return (
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: 10,
                          }}
                        >
                          <IconButton onClick={() => next()}>
                            <ArrowForwardIos color={"primary"} />
                          </IconButton>
                        </div>
                      );
                    }}
                  >
                    {images.map((item) => (
                      <img
                        src={item}
                        alt={item.title}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = EmptyLogo;
                        }}
                      />
                    ))}
                  </ResponsiveCarousel>
                </div>
              </Box>
            </Modal>
          </Grid>

          <Grid item xs={12} md={7}>
            <Box sx={{ m: 2 }}>
              <Typography variant={"h5"}>{item.name}</Typography>
              {item.prescription_required ? (
                <Typography color={"secondary"} fontWeight={"bold"}>
                  Prescription Required*
                </Typography>
              ) : null}
              {item.generic_name === null ? null : (
                <Typography variant={"p"} sx={{ fontSize: 12 }}>
                  <br /> Generic Name: {item.generic_name} <br />
                </Typography>
              )}
              {item.manufacturer === null ? null : (
                <Typography variant={"p"} sx={{ fontSize: 12 }}>
                  Manufacturer: {item.brand_name}
                </Typography>
              )}
            </Box>

            <Box
              sx={{
                padding: 2,
                margin: 2,
                borderRadius: 5,
                backgroundColor: "#fff",
              }}
            >
              <ToggleButtonGroup
                defaultValue={false}
                value={isPack}
                exclusive
                onChange={changeType}
                fullWidth
              >
                <ToggleButton color="primary" value={false}>
                  <Typography sx={{ fontWeight: "bold" }}>Unit</Typography>
                </ToggleButton>
                <ToggleButton color="secondary" value={true}>
                  <Typography sx={{ fontWeight: "bold" }}>Pack</Typography>
                </ToggleButton>
              </ToggleButtonGroup>

              <Box sx={{ p: 2 }}>
                <Typography
                  variant="h5"
                  color={"primary"}
                  sx={{ fontSize: 18 }}
                >
                  <b>Rs. {isPack ? item.price_pack : item.price_unit} </b>{" "}
                  {isPack &&
                    `/ ${item.pieces} Unit${
                      item.pieces !== 1 && "s"
                    } in each pack.`}
                </Typography>
                <Typography></Typography>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Stack direction={"row"}>
                  <Button
                    small
                    onClick={() =>
                      quantity > 1 ? setQuantity(quantity - 1) : quantity
                    }
                  >
                    <RemoveIcon />
                  </Button>
                  <TextField
                    margin="Normal"
                    type={"number"}
                    value={quantity}
                    variant={"outlined"}
                    sx={{ width: 70 }}
                    onChange={(e) => setQuantity(e.target.valueAsNumber)}
                  />
                  <Button small onClick={() => setQuantity(quantity + 1)}>
                    <AddIcon />
                  </Button>
                </Stack>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleClick();
                    props.addItemToCart(payloadData);
                  }}
                >
                  Add to Cart
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Stack direction={"row"} spacing={2}>
          <Button
            variant={
              selectedDescription === "description" ? "contained" : "outlined"
            }
            style={{
              textTransform: "none",
            }}
            onClick={() => setSelectedDescription("description")}
          >
            Descriptions
          </Button>
          <Button
            variant={
              selectedDescription === "howtouse" ? "contained" : "outlined"
            }
            style={{
              textTransform: "none",
            }}
            onClick={() => setSelectedDescription("howtouse")}
          >
            How to Use
          </Button>
          <Button
            variant={
              selectedDescription === "sideeffects" ? "contained" : "outlined"
            }
            style={{
              textTransform: "none",
            }}
            onClick={() => setSelectedDescription("sideeffects")}
          >
            Side Effects
          </Button>
          <Button
            variant={
              selectedDescription === "precautions" ? "contained" : "outlined"
            }
            style={{
              textTransform: "none",
            }}
            onClick={() => setSelectedDescription("precautions")}
          >
            Precautions
          </Button>
        </Stack>
        {selectedDescription === "description" && (
          <div
            dangerouslySetInnerHTML={{
              __html: item.descriptions_detail ? item.descriptions_detail : "",
            }}
          ></div>
        )}
        {selectedDescription === "howtouse" && (
          <div
            dangerouslySetInnerHTML={{
              __html: item.descriptions_use ? item.descriptions_use : "",
            }}
          ></div>
        )}
        {selectedDescription === "sideeffects" && (
          <div
            dangerouslySetInnerHTML={{
              __html: item.descriptions_sideeffect
                ? item.descriptions_sideeffect
                : "",
            }}
          ></div>
        )}
        {selectedDescription === "precautions" && (
          <div
            dangerouslySetInnerHTML={{
              __html: item.descriptions_precaution
                ? item.descriptions_precaution
                : "",
            }}
          ></div>
        )}
        <ProductContainer
          heading={"Similar Products"}
          products={similarProducts}
        />
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Product has been Added to your Cart{" "}
          <Button
            size={"small"}
            variant="outlined"
            sx={{ ml: 2 }}
            onClick={() => navigate("/Cart")}
          >
            View Cart
          </Button>
        </Alert>
      </Snackbar>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    addItemToCart: (data) => dispatch(actions.addToCart(data)),
  };
};

export default connect(null, mapDispatchToProps)(DetailsPage);
