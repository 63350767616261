import "./App.css";
import axios from "axios";
import Routes from "./Navigation/Routes";
import baseURL from "./Assets/common/baseURL";
import AuthGlobal from "./Context/store/AuthGlobal";

import React, { useEffect, useState, useContext } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: ["Rubik", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#2c3c93",
    },
    secondary: {
      light: "#0066ff",
      main: "#DA1212",
      contrastText: "#f9f9f9",
    },
    Whatsapp: {
      main: "#25D366",
      contrastText: "#f9f9f9",
      dark: "#3ddb78",
    },
    text: {
      main: "#fff",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 20,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 15,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          ":hover": {
            backgroundColor: "#DA1212",
            color: "#fff",
          },
        },
      },
    },
  },
});

function App() {
  const context = useContext(AuthGlobal);

  const [userProfile, setUserProfile] = useState([]);
  const [authStatus, setAuthStatus] = useState(false);

  useEffect(() => {
    if (context.stateUser.isAuthenticated === true) {
      const res = localStorage.getItem("jwt");

      axios
        .get(`${baseURL}/controller/Profile.php`, {
          headers: { Authorization: `${res}` },
        })
        .then((user) => {
          setUserProfile(user.data.data);
          if (user.data.data === null) {
            localStorage.removeItem("jwt");
          }
        })
        .catch((error) => {
          console.warn(error);
        });
      setAuthStatus(true);
      return setUserProfile();
    } else {
      setAuthStatus(false);
    }

    setAuthStatus(false);
  }, [context.stateUser.isAuthenticated]);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Routes authStatus={authStatus} userProfile={userProfile} />
      </ThemeProvider>
    </div>
  );
}

export default App;
