import React, { useEffect, useState, useContext } from 'react'
import { Container, Grid, Typography } from '@mui/material'

import axios from 'axios'
import baseURL from '../Assets/common/baseURL'
import TrackOrderCard from '../Components/TrackOrder/TrackOrderCard'

import LoaderImage from '../Assets/images/loader.gif'


function TrackOrder() {

    const [orderData, setOrderData] = useState([])
    const [orderCount, setOrderCount] = useState('')
    const [isLoading, SetLoading] = useState(false)

    useEffect(() => {
        loadOrders()
    }, []);

    const loadOrders = async () => {
        const res = localStorage.getItem('jwt')
        const profileData = await axios
            .get(`${baseURL}/controller/Profile.php`, {
                headers: { Authorization: `${res}` },
            })
            .then(user => {
                const userID = {
                    user_id: user.data.data.id
                }
                fetch(`${baseURL}getOrders`, {
                    method: "POST",
                    body: JSON.stringify(userID),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                })
                    .then((res) => res.json())
                    .then((data) => {
                        setOrderData(data.data.orders)
                        setOrderCount(data.data.orders_count)
                    })
            })
        SetLoading(true)
    }

    return (
        <div>
            {
                isLoading ? (
                    <Container maxWidth={'xl'} sx={{ p: 2, minHeight: '45vh' }} >
                        <div style={{ display: 'flex', justifyContent: 'center' }} >
                            <Typography variant='h3' >Your Orders</Typography>
                        </div>
                        <Typography variant={'h6'} ><b>Number of Orders: {orderCount}</b></Typography>
                        <Grid container >
                            {[...orderData].reverse().map((order) => {
                                return <TrackOrderCard data={order} />
                            })}
                        </Grid>
                    </Container >
                ) : (
                    <div style={{ minHeight: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <img src={LoaderImage} height={500} />
                    </div>
                )
            }
        </div>
    )
}

export default TrackOrder