import axios from "axios";
import Search from "../Menu/Search";
import MenuDrawer from "../Menu/MenuDrawer";
import logo from "../../Assets/images/logo.svg";
import MenuIcon from "@mui/icons-material/Menu";
import baseURL from "../../Assets/common/baseURL";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {
  Box,
  Badge,
  Button,
  AppBar,
  Toolbar,
  useTheme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import API from "../../utils/API";

function StickyHeader(props) {
  const theme = useTheme();
  const navigate = useNavigate();

  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [drawerMenuOpen, setDrawerMenuOpen] = useState(false);

  useEffect(() => {
    loadCategories();
  }, []);

  const loadCategories = async () => {
    const res = await API({
      method: "GET",
      url: "category",
    });
    setCategories(res);
    const subCategoriesRes = await API({
      method: "GET",
      url: "subcategory",
    });
    setSubCategories(subCategoriesRes);
  };

  return (
    <AppBar position="sticky" sx={{ background: "#fff" }}>
      <Toolbar
        variant="dense"
        sx={{ justifyContent: "space-between", marginTop: 1 }}
      >
        {isMatch && (
          <>
            <MenuIcon
              color="primary"
              fontSize="large"
              onClick={() => setDrawerMenuOpen(true)}
            />

            <MenuDrawer
              open={drawerMenuOpen}
              categories={categories}
              setOpen={setDrawerMenuOpen}
              authStatus={props.authStatus}
              subCategories={subCategories}
            />
          </>
        )}

        <div style={{ cursor: "pointer" }}>
          <img
            src={logo}
            alt={"NMP Pharmacy"}
            style={{ height: "90%", width: "90%" }}
            onClick={() => {
              navigate("/");
              document.documentElement.scrollTo(0, 0);
            }}
          />
        </div>

        {!isMatch && (
          <div style={{ marginRight: 20, marginLeft: 20, width: "40%" }}>
            <Search />
          </div>
        )}

        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          {!isMatch && <></>}
          <Box sx={{ cursor: "pointer" }} onClick={() => props.setOpen(true)}>
            <Badge
              badgeContent={props.cartItems.Items.length}
              color="secondary"
            >
              <ShoppingCartIcon color="primary" fontSize="large" />
            </Badge>
          </Box>
        </Box>
      </Toolbar>

      {!isMatch && (
        <>
          <Toolbar variant="dense">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div style={{ display: "flex" }}>
                {categories.map((category, index) => {
                  return (
                    <div key={index} className="navigation">
                      <a>{category.name}</a>

                      <div className="navigation-content">
                        {subCategories.map((subCategory) => {
                          if (
                            category.id === subCategory.parent_category &&
                            subCategory.name !== "General Items"
                          ) {
                            return (
                              <a
                                href={
                                  subCategory.name === "A to Z"
                                    ? "/showproducts"
                                    : `/subcategory/${subCategory.id}`
                                }
                                style={{ fontSize: 12 }}
                              >
                                {subCategory.name}
                              </a>
                            );
                          }

                          return null;
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div>
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{ mr: 2, textAlign: "start" }}
                  // onClick={() => navigate("/fastorder")}
                >
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      fontWeight: "600",
                      textTransform: "none",
                      // color: "white",
                    }}
                  >
                    Consult with Pharmacist
                  </Typography>
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ mr: 2, textAlign: "start" }}
                  onClick={() => navigate("/fastorder")}
                >
                  <Typography sx={{ whiteSpace: "nowrap", fontWeight: "600", textTransform: "none" }}>
                    Create Fast Order
                  </Typography>
                </Button>
              </div>
            </div>
          </Toolbar>
        </>
      )}
    </AppBar>
  );
}

const mapStateToProps = (state) => {
  const { cartItems } = state;
  return { cartItems: cartItems };
};

export default connect(mapStateToProps)(StickyHeader);
