import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Button,
  TextField,
  Box,
  Typography,
  CssBaseline,
} from "@mui/material";

import baseURL from "../../Assets/common/baseURL";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

function PhoneNumber() {
  const navigate = useNavigate();

  const [phone, setPhone] = useState("");

  const checkAccount = () => {
    const phoneNumber = {
      phone,
    };

    if (phone.length >= 11 || phone.length <= 13) {
      fetch(`${baseURL}checkAccount`, {
        method: "POST",
        body: JSON.stringify(phoneNumber),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            const message = data.messages;
            if (message == 'User Does not Exists') {
              navigate("/account/OTP", {state: phone });
            }
            if (message == 'User Exists') {
              navigate("/account/signin", {state: phone });
            }
          }
        });
    } else {
      console.log("Wrong Phone Number");
    }
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ display: 'flex', height: '100vh', alignItems: 'center' }}>
      <CssBaseline />
      
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
          padding: 4,
          borderRadius: 5,

          textAlign: 'left'
        }}
      >
        <KeyboardBackspaceIcon onClick={() => navigate('/')} color="primary" sx={{ mb: 2 }}  />
        <Typography component="h1" variant="h5">
          <b>Enter Phone Number</b>
        </Typography>
        <Typography variant="p">
          Let us check if you have an account?
        </Typography>
        <Box
          sx={{ mt: 4 }}
        >
          <TextField
            id="phone"
            type="tel"
            label="Phone Number"
            placeholder="03XXXXXXXXX"
            margin="normal"
            required
            fullWidth
            onChange={(input) => setPhone(input.target.value)}
          />
          <Button
            variant="contained"
            type="submit"
            margin="normal"
            onClick={checkAccount}
            fullWidth
            sx={{ mt: 2 }}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default PhoneNumber;
