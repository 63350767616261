import React from 'react'
import { useLocation } from 'react-router-dom'

// Material UI
import { Box, Card, CardContent, CardMedia, Container, Divider, Grid, Typography } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

// Images
import AboutHeader from '../../Components/AboutHeader';

function TeamMember() {

    const teamMember = useLocation().state.item

    return (
        <>
            <AboutHeader>
                {teamMember.name}
            </AboutHeader>
            <Container maxWidth={'lg'} sx={{ mt: 5, mb: 5 }} >
                <Grid container spacing={2} >
                    <Grid item xs={12} md={4}  >
                        <Card>
                            <CardMedia
                                component={'img'}
                                src={teamMember.image}
                                height={320}
                                sx={{ objectPosition: 'top' }}
                            />
                            <CardContent>
                                <Typography variant={'h6'} fontWeight={'bold'} >{teamMember.designation}</Typography>
                                <Divider sx={{ mt: 1, mb: 1 }} />
                                <Box display={'flex'} alignItems={'center'} >
                                    <EmailIcon color={'primary'} fontSize={'small'} />
                                    <Typography> {teamMember.email}</Typography>
                                </Box>
                                <Box display={'flex'} alignItems={'center'} >
                                    <PhoneIcon color={'primary'} fontSize={'small'} />
                                    <Typography> {teamMember.phone_number}</Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={8} >
                        <Typography>{teamMember.dsescription}</Typography>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default TeamMember