import axios from "axios";
import Banner from "../Components/Banner";
import LoaderImage from "../Assets/images/loader.gif";
import ProductContainer from "../Components/Products/ProductContainer";
import FeedbackContainer from "../Components/Feedback/FeedbackContainer";

import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Stack,
  useTheme,
  Typography,
  useMediaQuery,
  CardActionArea,
} from "@mui/material";
import API from "../utils/API";

function HomePage() {
  const theme = useTheme();
  const navigate = useNavigate();

  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const [banners, setBanners] = useState([]);
  const [type, setType] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  const [isLoading, SetLoading] = useState(false);

  useEffect(() => {
    loadProducts();
    // loadFeedbacks();
  }, []);

  const loadProducts = async () => {
    const res = await API({
      method: "GET",
      url: "slider",
    });
    setBanners(res);
    const typeRes = await API({
      method: "GET",
      url: "type",
    });
    typeRes.map(async (item, index) => {
      const productRes = await API({
        method: "GET",
        url: `product?type=${item.id}`,
      });
      setType((prev) => [
        ...prev,
        { id: item.id, name: item.name, data: productRes },
      ]);
    });

    SetLoading(true);
  };

  // const loadFeedbacks = () => {
  //   axios.get(`${baseURL}getFeedbacks`).then((res) => {
  //     setFeedbacks(res.data.data.feedback);
  //   });
  // };

  return (
    <div>
      {isLoading ? (
        <div>
          <Banner banners={banners} />
          <Box sx={{ m: 2 }}>

            {type
              ?.sort((a, b) => a.id - b.id)
              ?.map((item, index) => (
                <ProductContainer
                  key={index}
                  heading={item.name}
                  products={item.data}
                />
              ))}
            {/* <FeedbackContainer heading={"Reviews"} feedbacks={feedbacks} /> */}
          </Box>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            minHeight: "30vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={LoaderImage} height={500} alt="Alt value" />
        </div>
      )}
    </div>
  );
}

export default HomePage;
