import axios from "axios";
import FlatList from "flatlist-react";
import SortIcon from "@mui/icons-material/Sort";
import baseURL from "../../Assets/common/baseURL";
import LoaderImage from "../../Assets/images/loader.gif";
import ProductCard from "../../Components/Products/ProductCard";

import { useParams } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Link,
  Menu,
  Button,
  MenuItem,
  useTheme,
  Container,
  Typography,
  Breadcrumbs,
  useMediaQuery,
} from "@mui/material";

function SubCatProducts() {
  const theme = useTheme();
  let { subCatID } = useParams();

  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const [products, setProducts] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [subCategory, setSubCategory] = useState();

  const fetchProducts = useCallback(async () => {
    await axios
      .get(`${baseURL}subcategory/${subCatID}`)
      .then((res) => setProducts(res.data.data.products))
      .catch(() => setLoading(true));

    axios.get(`${baseURL}subcategories`).then((res) =>
      res.data.data.sub_categories.forEach((element) => {
        if (element.id === subCatID) {
          setSubCategory(element.name);
        }
      })
    );

    setLoading(true);
  }, [subCatID]);

  useEffect(() => {
    setLoading(false);

    fetchProducts();
  }, [fetchProducts]);

  const renderProduct = (item) => {
    return <ProductCard product={item} responsive={true} />;
  };

  //Sort Menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {isLoading ? (
        <Container maxWidth={"xl"} sx={{ mt: 2 }}>
          <Breadcrumbs>
            <Link underline="hover" color="inherit" href="/">
              Home
            </Link>
            <Link underline="hover" color="inherit">
              {subCategory}
            </Link>
          </Breadcrumbs>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              id="sort-menu-button"
              aria-controls={open ? "sort-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <Typography fontSize={18} sx={{ mr: 1 }}>
                Sort By:{" "}
              </Typography>
              <SortIcon fontSize="large" />
            </Button>
            <Menu
              id="sort-menu"
              aria-labelledby="sort-menu-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  setProducts([...products].sort((a, b) => a.price - b.price));
                }}
              >
                Price: Low to High
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  setProducts([...products].sort((a, b) => b.price - a.price));
                }}
              >
                Price: High to Low
              </MenuItem>
            </Menu>
          </Box>
          <Box>
            <FlatList
              list={products}
              renderItem={renderProduct}
              renderWhenEmpty={() => (
                <div
                  style={{
                    display: "flex",
                    minHeight: "40vh",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography fontSize={22} fontWeight={"bold"}>
                    No Products Found
                  </Typography>
                </div>
              )}
              renderOnScroll
              display={{
                grid: true,
                gridGap: isMatch ? "1px" : "100px",
              }}
            />
          </Box>
        </Container>
      ) : (
        <div
          style={{
            minHeight: "30vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={LoaderImage} height={500} alt="loader..." />
        </div>
      )}
    </div>
  );
}

export default SubCatProducts;
