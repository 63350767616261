import React, { useState } from 'react'
import { Box, Breadcrumbs, Button, Container, Link, Rating, TextField, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import baseURL from '../Assets/common/baseURL'


function Feedback(props) {

    const navigate = useNavigate()

    const userProfile = props.userProfile

    const [feedback, setFeedback] = useState()
    const [rating, setRating] = useState(1)

    const handlePublish = () => {
        const data = {
            user_id: userProfile.id,
            feedback
        }

        fetch(`${baseURL}createFeedback`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then(res => res.json())
            .then(data => {
                if (data.success === true) {
                    navigate('/')
                }
            })
    }

    return (
        <Container maxWidth='sm' sx={{ mt: 5, mb: 5 }} >
            <Breadcrumbs>
                <Link underline="hover" color="inherit" href="/" >
                    Home
                </Link>
                <Link underline="hover" color="inherit" >
                    Feedback
                </Link>
            </Breadcrumbs>
            <Typography variant='h3' >Feedback</Typography>
            <Box>
                <Box margin={2} >
                    <Typography>Name: <b>{userProfile ? userProfile.name : ''}</b></Typography>
                    <Typography>Email: <b>{userProfile ? userProfile.email : ''}</b></Typography>
                </Box>
                <Typography component="legend">Rating</Typography>
                <Rating value={rating} onChange={(event, value) => setRating(value)} />
                <TextField
                    fullWidth
                    id='feedback'
                    label='Your FeedBack'
                    onChange={(event) => {
                        setFeedback(event.target.value)
                    }}
                    multiline
                    rows={4}
                    margin='normal'
                />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button variant='contained' onClick={handlePublish} >Publish</Button>
                </Box>
            </Box>
        </Container>
    )
}

export default Feedback