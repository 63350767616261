import baseURL from "../Assets/common/baseURL";
import AboutHeader from "../Components/AboutHeader";

import React, { useState } from "react";
import { Circle } from "@mui/icons-material";
import {
  Box,
  Grid,
  List,
  Modal,
  Stack,
  Alert,
  Button,
  ListItem,
  Snackbar,
  Container,
  TextField,
  Typography,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from "@mui/material";

function Franchise() {
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackBar] = useState(false);

  const upload = new FormData();

  const submitApplication = async (event) => {
    event.preventDefault();
    setLoading(true);

    const data = new FormData(event.currentTarget);
    upload.append("to", `info@nmp.com.pk`);
    upload.append("toName", `Franchise`);
    upload.append("subject", `Franchise`);
    upload.append(
      "body",
      `First Name: ${data.get("first-name")}<br>Last Name: ${data.get(
        "last-name"
      )}<br>Email: ${data.get("email")}<br>Phone: ${data.get(
        "phone"
      )}<br>Amount to Invest: ${data.get("amount")}<br>When to open: ${data.get(
        "when"
      )}<br>Places Details: ${data.get(
        "places"
      )}<br>Operation Timing: ${data.get("operation")}<br>City: ${data.get(
        "city"
      )}<br>Details: ${data.get("details")}<br>`
    );

    await fetch(`${baseURL}sendEmail`, {
      method: "POST",
      body: upload,
    }).then(() => {
      setLoading(false);
      setSnackBar(true);
      document.getElementById("franchise-form").reset();
    });
  };

  return (
    <>
      <AboutHeader>Franchise Opportunity</AboutHeader>
      <Container sx={{ mt: 5, mb: 5 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Box
              component={"form"}
              id="franchise-form"
              onSubmit={submitApplication}
            >
              <Stack spacing={3}>
                <Stack direction={"row"} spacing={2}>
                  <TextField
                    id={"first-name"}
                    name={"first-name"}
                    size="small"
                    fullWidth
                    label={"First Name"}
                  />
                  <TextField
                    id={"last-name"}
                    name={"last-name"}
                    size="small"
                    fullWidth
                    label={"Last Name"}
                  />
                </Stack>
                <Stack direction={"row"} spacing={2}>
                  <TextField
                    id={"email"}
                    name={"email"}
                    size="small"
                    fullWidth
                    required
                    label={"Email"}
                  />
                  <TextField
                    id={"phone"}
                    name={"phone"}
                    size="small"
                    fullWidth
                    label={"Phone"}
                  />
                </Stack>
                <Stack direction={"row"} spacing={2}>
                  <TextField
                    id={"amount"}
                    name={"amount"}
                    size="small"
                    fullWidth
                    label={"Amount you want to Invest"}
                  />
                  <TextField
                    id={"when"}
                    name={"when"}
                    size="small"
                    fullWidth
                    label={"When to open"}
                  />
                </Stack>
                <Stack direction={"row"} spacing={2}>
                  <TextField
                    id={"places"}
                    name={"places"}
                    size="small"
                    fullWidth
                    label={"Enter Place Details"}
                  />
                  <TextField
                    id={"operation"}
                    name={"operation"}
                    size="small"
                    fullWidth
                    label={"Enter Operation Timing"}
                  />
                </Stack>
                <Stack spacing={2}>
                  <TextField
                    id={"city"}
                    name={"city"}
                    size="small"
                    fullWidth
                    label={"City"}
                  />
                  <TextField
                    id={"details"}
                    name={"details"}
                    size="small"
                    fullWidth
                    label={"Details"}
                  />
                </Stack>
              </Stack>
              <Box display={"flex"} justifyContent={"flex-end"} margin={3}>
                <Button type={"submit"} variant={"contained"} size={"large"}>
                  Submit
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h4">Franchise Benefit’s</Typography>
            <List dense={2}>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Right to use our Trade Mark & Logo" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Site Evaluation" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Marketing Support" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Purchase Order’s Assistance as per Area Demand" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Staff Training’s Session’s" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Regulatary & Licensing Assistance" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Availability of Dispensing products" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Online Pharmacy Support" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Home Delivery Order’s Through our App & Website" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Store Renovation Plan" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Vendors List for Renovation & interior" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Sale’s Target’s Planing" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Sale’s Working through Marketing Team" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Over All Consultancy on operational Matter’s" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Help in Staff Hiring" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Internal Audit Training’s" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Circle fontSize={"1"} />
                </ListItemIcon>
                <ListItemText primary="Inventory Managment Training’s" />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>
      <Modal
        open={loading}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <CircularProgress />
      </Modal>
      <Snackbar
        open={snackbar}
        autoHideDuration={6000}
        onClose={() => setSnackBar(false)}
      >
        <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
          Your submission has been received.
        </Alert>
      </Snackbar>
    </>
  );
}

export default Franchise;
