import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import {
  TextField,
  Button,
  Container,
  CssBaseline,
  Typography,
  Box,
  Modal,
  Checkbox,
  FormGroup,
  FormControl,
  FormControlLabel,
  Alert,
} from "@mui/material";

import { loginUser } from "../../Context/actions/Auth.actions";
import AuthGlobal from "../../Context/store/AuthGlobal";
import baseURL from "../../Assets/common/baseURL";

function Signup() {

  const context = useContext(AuthGlobal)

  const phone = useLocation().state

  const [fullname, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [fullnameError, setNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [consent, setConsent] = useState(false)

  const [emailExists, setEmailExists] = useState(false)

  const user = {
    fullname,
    email,
    phone,
    password,
  };

  const onSignUpPressed = (e) => {
    if (fullname.length !== 0) {
      setNameError(false)
      if (password.length !== 0) {
        setPasswordError(false)
        if (consent === true) {
          fetch(`${baseURL}register`, {
            method: 'POST',
            body: JSON.stringify(user),
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          })
            .then(res => res.json())
            .then(data => {
              if (data) {
                const message = data.messages;
                if (message == 'User Created') {
                  const user = {
                    phone,
                    password,
                  };
                  loginUser(user, context.dispatch);
                }
                if (message == 'Email Already Exists') {
                  setEmailExists(true)
                }
              }
            })
            .catch(err => {
              console.log(err);
            });
        }
      }
    }
    if (fullname.length === 0) {
      setNameError(true)
    }
    if (password.length === 0) {
      setPasswordError(true)
    }
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ display: 'flex', height: '100vh', alignItems: 'center' }}>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#fff",
          padding: 5,
          borderRadius: 10,
        }}
      >
        <Typography component="h1" variant="h4">
          Register
        </Typography>
        <Box sx={{ mt: 1 }}>
          <TextField
            label="Full Name"
            name="name"
            type="text"
            id="name"
            autoComplete="current-password"
            margin="normal"
            required
            fullWidth
            value={fullname}
            error={fullnameError}
            onChange={(event) => setName(event.target.value)}
          />
          <TextField
            label="Email"
            name="email"
            type="email"
            id="email"
            autoComplete="current-password"
            margin="normal"
            fullWidth
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <TextField margin="normal" fullWidth label="Phone" value={phone} disabled />
          <TextField
            label="Password"
            name="password"
            type="password"
            id="password"
            autoComplete="current-password"
            margin="normal"
            required
            fullWidth
            value={password}
            error={passwordError}
            onChange={(event) => setPassword(event.target.value)}
          />
          {emailExists ? <Alert severity="error" >Email Already Exists</Alert> : null}
          <div style={{ display: 'flex' }} >
            <Checkbox color="primary" value={consent} onChange={(e) => setConsent(e.target.checked)} />
            <Typography>I have read and agree to <a href="https://nmp.com.pk/privacy-policy" target='_blank' >Privacy Policy</a> and <a href="https://nmp.com.pk/shipping-and-returns" target='_blank' >Shipping {`&`} Returns</a></Typography>
          </div>
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 3, mb: 2 }}
            onClick={onSignUpPressed}
          >
            Sign Up
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default Signup;
