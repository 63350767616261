import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Material UI
import { Box, Card, CardActionArea, CardContent, CardMedia, Collapse, Container, Grid, Typography } from '@mui/material'

//API
import axios from 'axios'
import baseURL from '../../Assets/common/baseURL'

// images
import LoaderImage from '../../Assets/images/loader.gif'
import AboutHeader from '../../Components/AboutHeader'
import API from '../../utils/API'

function TeamMemberCard({ item }) {

    const navigate = useNavigate()

    const [cardColors, setCardColors] = useState({
        primaryText: 'primary',
        secondaryText: null,
        background: '#fff'
    })


    const initializeColors = () => {
        setCardColors({
            primaryText: 'primary',
            secondaryText: null,
            background: '#fff'
        })
    }

    const setColors = () => {
        setCardColors({
            primaryText: '#fff',
            secondaryText: '#fff',
            background: '#2c3c93'
        })
    }

    return (
        <Grid item xs={12} md={3} >
            <Card
                onMouseEnter={setColors}
                onMouseLeave={initializeColors}
            >
                <CardActionArea onClick={() => navigate('/team-member', { state: { item } })} >
                    <CardMedia
                        component={'img'}
                        src={item.image}
                        height={200}
                        sx={{ objectPosition: 'top' }}
                    />
                    <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: cardColors.background }} >
                        <Box textAlign={'center'} >
                            <Typography variant='h6' fontWeight={'bold'} color={cardColors.primaryText} >{item.name}</Typography>
                            <Typography color={cardColors.secondaryText} >{item.designation}</Typography>
                        </Box>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    )
}

function OurTeam() {

    const [isLoading, setLoading] = useState(false)
    const [teamMembers, setTeamMembers] = useState([])



    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        const res = await API({
            method: 'GET',
            url: 'team/'
        })
        setTeamMembers(res)
        setLoading(true)
    }



    return (
        <div>
            <AboutHeader>
                Our Team
            </AboutHeader>
            <Container maxWidth={'lg'} sx={{ mt: 5, mb: 5 }} >
                {isLoading ? (
                    <Grid container spacing={4} >
                        {teamMembers.map((item) => {
                            return (
                                <TeamMemberCard item={item}  />
                            )
                        })}
                    </Grid>
                ) : (
                    <div style={{ minHeight: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        <img src={LoaderImage} height={500} />
                    </div>
                )}
            </Container>
        </div>
    )
}

export default OurTeam