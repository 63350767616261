import React, { useContext, useEffect, useState } from "react";

//Material UI
import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  IconButton,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import API from "../../utils/API";
import AuthGlobal from "../../Context/store/AuthGlobal";

function ProfileOrders() {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const context = useContext(AuthGlobal);

  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [orderCount, setOrderCount] = useState(0);

  useEffect(() => {
    loadOrders();
  }, []);

  const loadOrders = async () => {
    try {
      const res = await API({
        method: "GET",
        url: "invoice/?customer=" + context.stateUser.user.user,
      });
      setOrderData(res);
      setOrderCount(res.length);
    } catch (e) {
      console.log(e);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMatch ? 300 : 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 2,
  };

  return (
    <Container sx={{ mt: 2, mb: 2, minHeight: "30vh" }}>
      <Typography>Total Orders: {orderCount}</Typography>
      <div style={{ overflowY: "scroll" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Order #</b>
              </TableCell>
              <TableCell>
                <b>Date</b>
              </TableCell>
              <TableCell>
                <b>Total Amount</b>
              </TableCell>
              <TableCell>
                <b>Status</b>
              </TableCell>
              {/* <TableCell>
                <b>Details</b>
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {orderData.map((item) => {
              return (
                <TableRow>
                  <TableCell>{item.invoice_no}</TableCell>
                  <TableCell>{item.invoice_date}</TableCell>
                  <TableCell>{item.total_amount}</TableCell>
                  <TableCell>
                    {item.delivery_status !== null && (
                      <Chip size="small" label={item.delivery_status} />
                    )}
                  </TableCell>
                  {/* <TableCell>
                    <Button
                      onClick={() => {
                        setOpen(true);
                        setModalData(item.items);
                      }}
                    >
                      View
                    </Button>
                  </TableCell> */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Modal open={open} onClose={() => setOpen(false)}>
          <Box sx={style}>
            <Box display={"flex"} justifyContent={"flex-end"}>
              <IconButton onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Stack spacing={3}>
              <Typography fontSize={22} fontWeight={"bold"}>
                Product Details
              </Typography>
              {modalData.map((item) => {
                return (
                  <div>
                    <Typography>
                      <b>Product Name: </b>
                      {item.product_name}
                    </Typography>
                    <Typography>
                      <b>Quantity: </b>
                      {item.quantity} {item.qty_type}
                    </Typography>
                    <Divider />
                  </div>
                );
              })}
            </Stack>
          </Box>
        </Modal>
      </div>
    </Container>
  );
}

export default ProfileOrders;
